.projectsContainer {
    margin: 0 auto;
    max-width: 80%;
}

.project {
    background-color: var(--projectColour);
    padding: .5rem 1rem;
    margin-bottom: .5rem;
    border-radius: 5px;
    border: 1px solid black;
}

.project:last-child {
    margin-bottom: 2rem;
}

.projectDetails {
    display: flex;
}

.projectDescription {
    padding: 0 1em;
    margin-top: 0;
}

.projectText {
    flex: 2;
}

.projectLanguages {
    font-size: small;
}

.projectLogo {
    flex: 1;
    max-width: 30%;
    height: fit-content;
    margin: 0 1em;
}

.projectTitle {
    text-decoration: underline;
    text-align: center;
}

.strikethrough {
    text-decoration: line-through;
}

@media screen and (max-width: 1306px) {
    .projectDetails {
        flex-direction: column-reverse!important;
    }

    .projectLogo {
        min-width: 30%;
        margin-bottom: 1rem;
    }
}