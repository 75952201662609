* {
    box-sizing: border-box;
}


h3 {
    margin: 0;
}

a.link {
    text-decoration: none;
    color: inherit;
}

.link {
    background-color: var(--linkColour);
    border-radius: 6px;
    border: 1px solid black;
    text-align: center;
    transition-duration: .5s;
    flex: 25%;
    padding: 1em;
}

/* All but the first row will have a margin on its top */
.link:nth-child(n+4) {
    margin-top: 2em;
}

.link:not(:nth-child(3n+1)) {
    margin-left: 2em;
}

.link:hover {
    background-color: var(--linkHoverColour);
}

.linksContainer {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
}

.icon {
    width: 2rem;
}

@media screen and (max-width: 1306px) {
    .link:not(:nth-child(3n+1)) {
        margin-left: 0;
    }

    .link:nth-child(n+1) {
        margin-top: 1em;
    }
    
    .link {
        flex-direction: column;
        flex: 100%;
    }
}