.aboutContent {
    display: block;
}

.studyObject:nth-child(n+2) {
    padding-top: .5rem;
}

.studyTitle {
    text-align: center;
    font-size: larger;
}

.aboutSchool {
    display: flex;
    padding-top: 1rem;
    flex-flow: row;
}

.schoolStory {
    text-align: justify;
    flex: 2;
}

.schoolDiploma {
    margin-left: 2em;
    max-width: 45%;
    flex: 1;
}

.studyGrade {
    text-align: right;
    color: lightgrey;
    font-size: 14px;
}

@media screen and (max-width: 1306px) {
    .aboutSchool {
        flex-flow: column;
    }

    .schoolStory {
        padding-bottom: 1rem;
    }

    .schoolDiploma {
        margin-left: 0;
        max-width: 100%;
    }
}