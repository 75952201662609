.graduationPic {
    flex: 1;
    max-width: 30%;
    margin-left: 2em;
}

.disclaimer {
    color: rgba(255, 0, 0, 0.75);
}

.landingContent {
    display: flex;
    flex-direction: row;
}

.landingStory {
    flex: 2;
}

@media screen and (max-width: 1306px) {
    .graduationPic {
        margin: 2em 0 0 0;
        max-width: 50%;
        align-self: center;
    }

    .landingContent {
        flex-direction: column;
    }
}