#header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    height: var(headerHeight);
    /* height: 11vh; */
    border-bottom: 0px solid var(--borderColour);
}

.navLink {
    display: grid;
    align-content: center;
    font-size: large;
    text-align: center;
    text-decoration: none;
    transition-duration: 500ms;
    min-width: 8vw;
    user-select: none;
    color: var(--navLinkColour);
}

.navLink:hover {
    background-color: var(--linkHoverColour);
    /* text-decoration: underline; */
}

/* .navLink:visited {
    color: var(--navLinkColour);
} */

.logo {
    padding: 1vh;
    min-width: 5vw;
    text-align: center;
}

.logo img {
    height: 5em;
}